import { render, staticRenderFns } from "./Ok.vue?vue&type=template&id=aedf67aa&scoped=true&"
import script from "./Ok.vue?vue&type=script&lang=js&"
export * from "./Ok.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aedf67aa",
  null
  
)

export default component.exports