<template>
    <div>please wait</div>
</template>

<script>
    /* eslint-disable */
    export default {
        name: "Ok",
        mounted() {
            this.$loadScript('https://api.ok.ru/js/fapi5.js').then(() => {
                var args = FAPI.Util.getRequestParameters(window.location.hash);
                if (args.session_secret_key && args.access_token) {
                    let form = new FormData();
                    form.set('access_token', args.access_token);
                    form.set('session_secret_key', args.session_secret_key);
                    let url = this.$config.api_url + 'User.okLogin';
                    this.$http.post(url, form).then((response) => {
                        this.$user = response.data;
                        this.$router.push({name: 'Home'});
                    }).catch(() => {
                        this.$user = null;
                    })
                }
            });

        }
    }
</script>

<style scoped>

</style>
